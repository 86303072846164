import React, { useState, useEffect } from 'react';
import './PhonemePage.css';
import { useParams, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';


const phonemeList = {
  consonants: ['p', 'b', 't', 'd', 'k', 'g', 'f', 'v', 's', 'z', 'θ', 'ð', 'ʃ', 'ʒ', 'tʃ', 'dʒ', 'h', 'w', 'n', 'm', 'init_ɹ', 'ɹ', 'l', 'ɾ', 's_o', 's_r', 's_h'],
  vowels: ['ɑ', 'æ', 'i', 'ɪ', 'ɛ', 'ɜ', 'ʌ', 'u', 'ʊ', 'o', 'ə', 'ɔ', 'jɑ', 'jæ', 'jɛ', 'jɜ', 'jʌ', 'ju', 'jɔ'],
  bases: ['b_k', 'b_t', 'b_m', 'b_n', 'b_p', 'b_l', 'b_ɹm', 'b_ɹn', 'b_ŋ']
};

const PhonemePage = () => {
  const navigate = useNavigate();
  const { _phoneme: urlPhoneme } = useParams();
  const [currentPhoneme, setCurrentPhoneme] = useState(urlPhoneme);
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (urlPhoneme !== currentPhoneme) {
      setCurrentPhoneme(urlPhoneme);
    }
  }, [urlPhoneme, currentPhoneme]);

  useEffect(() => {
    if (currentPhoneme) {
      fetch(`/descriptions/${currentPhoneme}.md`)
        .then(response => response.text())
        .then(text => setDescription(text))
        .catch(error => console.error('Error loading the markdown file:', error));
    }
  }, [currentPhoneme]);

  const handleClick = (_phoneme, type) => {
    setCurrentPhoneme(_phoneme);
    navigate(`/phoneme/${_phoneme}`);
  };

  return (
    <div className="system-container">
        <div className="content">
            <div className="title">
                <h1 className="app-title">음소 소개</h1>
            </div>

            <div className="phoneme-list">
              <b>궁금한 음소를 클릭해보세요!</b>
              <div className="phoneme-grid">
                {Object.entries(phonemeList).map(([type, list]) =>
                  list.map(_phoneme => (
                    <div
                     key={_phoneme}
                     className={`phoneme-cell ${currentPhoneme === _phoneme ? 'selected' : ''}`}
                     onClick={() => handleClick(_phoneme, type)}>
                      <img src={`/images/${type}/${_phoneme}.svg`} alt={_phoneme} />
                    </div>
                  ))
                )}
              </div>

            </div>

            <div className="description">
                <ReactMarkdown>{description}</ReactMarkdown>                
            </div>
        </div>
    </div>
  );
}

export default PhonemePage;
