import React from 'react';
import './PhonemeRule.css';
import { useNavigate } from 'react-router-dom';
import { FaRegQuestionCircle } from 'react-icons/fa';



const PHONEME_DESCRIPTIONS = {
  // Consonatns
  'p': 'ㅍ 발음과 비슷해요.',
  'b': '가운데에서는 ㅂ과 동일해요. 단어 맨 앞에서는 (으)ㅂ로 발음해보세요. (으)는 작고 빠르게',
  't': 'ㅌ 발음과 비슷해요',
  'd': 'ㄷ 발음에 성대 울림을 더 세게 하세요. 단어 맨 앞에서는 (으)ㄷ로 발음해보세요. (으)는 작고 빠르게',
  'k': 'ㅋ발음과 비슷해요',
  'g': 'ㄱ 발음에 성대 울림을 더 세게하세요. 단어 맨 앞에서는 (으)ㄱ로 발음해보세요. (으)는 작고 빠르게',
  'f': '비슷한 발음이 없어요. 윗니를 아랫입술에 가볍게 대고 숨을 내쉬듯이 발음하면 됩니다',
  'v': '비슷한 발음이 없어요. 윗니를 아랫입술에 가볍게 대고 숨을 내쉬면서 목에서 소리가 나도록 발음하면 됩니다',
  's': '쓰\'와 비슷하지만, 성대를 울리지 않고 바람 소리만',
  'z': '벌이 날아다니는 소리 zzz',
  'θ': '비슷한 발음이 없어요. 혀끝을 윗니와 아랫니 사이에 끼우고 바람을 내면서 발음하면 됩니다',
  'ð': '비슷한 발음이 없어요. 혀끝을 윗니와 아랫니 사이에 끼우고 바람을 내면서 목에서 소리가 나도록 발음하면 됩니다',
  'ʃ': '쉬를 자음으로. Shoot는 \'쉬ㅜㅌ\' (O), \'슛\'(X)',
  'ʒ': '벌이 날아다니는 소리 zzz에서 \'z쥐\'를 자음으로 취급해서 발음해보세요',
  'tʃ': '\'취\'를 자음으로 취급해서 발음해 보세요. chip는 츂',
  'dʒ': '\'쥐\'를 자음으로 취급해서 발음해 보세요. Jet는 쥐ㅔㅌ',
  'h': 'ㅎ발음과 비슷합니다',
  'w': '우!\' 자체를 자음으로 취급해서 발음해 보세요. Wow는 \'우\'아우',
  'n': 'ㄴ 발음과 비슷합니다. 맨 앞에서는 (은)ㄴ로 발음하세요. (은)은 작고 빠르게',
  'm': 'ㅁ 발음과 비슷합니다. 맨 앞에서는 (음)ㅁ로 발음하세요. (음)은 작고 빠르게',
  'init_ɹ': '첫 소리 r은 \'우뢰\'할때 ㄹ과 가깝습니다. (우)ㄹ로 발음하세요',
  'ɹ': '비슷한 발음이 없어요 ɹ',
  'l': '랄라\'에서 둘째 ㄹ 발음. 맨 처음 소리는 (을)ㄹ로 발음해 보세요. (을)은 작고 빠르게',
  'ɾ': 'ㄹ발음과 거의 비슷합니다',
  's_o': '자음없을때 빈칸 채우는 한글 ㅇ',
  's_r': 'ㄹ발음과 거의 비슷합니다',
  's_h': '부산말 e^2할때 e발음. 이-가 아니라 이! 옛한글 ㆆ발음',

  // Vowel
  'ɑ': 'ㅏ발음인데, 훨씬 뒤쪽에서 소리나게',
  'æ': 'ㅐ+ㅏ, 둘을 빠르게, 혹은 섞어서 발음',
  'i': 'ㅣ를 의식적으로 길게 발음.',
  'ɪ': 'ㅣ를 의식적으로 짧고 입을 작게 벌려 발음.',
  'ɛ': 'ㅔ발음. ㅐ발음보다 입 작게 발음',
  'ɜ': 'ㅡ+ㅓ, 둘을 빠르게, 혹은 섞어서 발음',
  'ʌ': 'ㅓ 발음과 비슷',
  'u': 'ㅜ 발음과 비슷',
  'ʊ': 'ㅡ 발음과 비슷',
  'o': 'ㅗ 발음과 비슷',
  'ə': 'ㅡ+ㅓ, 둘을 빠르게, 혹은 섞어서 발음',
  'ɔ': 'ㅗ+ㅓ, 둘을 빠르게, 혹은 섞어서 발음',
  'jɑ': 'ㅑ발음인데, 훨씬 뒤쪽에서 소리나게',
  'jæ': 'ㅒ+ㅏ, 둘을 빠르게, 혹은 섞어서 발음',
  'jɛ': 'ㅖ 발음. ㅒ발음보다 입 작게 발음',
  'jɜ': 'ㅕ+ㅡ, 둘을 빠르게, 혹은 섞어서',
  'jʌ': 'ㅕ 발음과 비슷',
  'ju': 'ㅠ 발음과 비슷. 길게: 유우',
  'jɔ': 'ㅛ+ㅓ, 둘을 빠르게, 혹은 섞어서 발음',
  
  // Bases
  'b_k': '한국어 ㅋ 받침과 비슷해요',
  'b_t': '한국어 ㅌ 받침과 비슷해요',
  'b_m': '한국어 ㅁ 받침과 비슷해요',
  'b_n': '한국어 ㄴ 받침과 비슷해요',
  'b_p': '한국어 ㅍ 받침과 비슷해요',
  'b_l': '한국어 ㄹ 받침과 비슷해요',
  'b_ɹm': '영어의 rㄹ 발음이 섞이면서 ㅁ 받침을 섞어서 발음해보세요',
  'b_ɹn': '영어의 rㄹ 발음이 섞이면서 ㄴ 받침을 섞어서 발음해보세요',
  'b_ŋ': '한글 ㅇ받침'
};

const mapTypeToFolder = (type) => {
  switch (type) {
    case 'first_consonant':
      return 'consonants';
    case 'vowel':
      return 'vowels';
    case 'final_consonant':
      return 'bases';
    default:
      return '';
  }
};

const Rule = ({ phoneme, type }) => {
  const navigate = useNavigate(); // navigate 함수를 가져옵니다.

  if (!phoneme) return null;

  const _phoneme = type === 'final_consonant' ? `b_${phoneme}` : phoneme;
  const description = PHONEME_DESCRIPTIONS[_phoneme];
  const imagePath = `/images/${mapTypeToFolder(type)}/${_phoneme}.svg`;

  const handleNavigate = () => {
    navigate(`/phoneme/${_phoneme}`);
  };

  return (
    <div className="phoneme-rule">
      <div>
        <img src={imagePath} alt={phoneme} />
      </div>
      <span>{description}</span>
      <div className="detail-button" onClick={handleNavigate}>
        <FaRegQuestionCircle /> 
      </div>
    </div>
  );
};

export default Rule;
