import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Card from './Card.js';
import MainBundle from './MainBundle.js';
import SubBundle from './SubBundle.js';
import './App.css';
import { FaSearch, FaTimes, FaInfoCircle, FaFlask, FaDonate, FaEnvelope } from 'react-icons/fa'; // FaTimes 추가
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import SearchWord from './SearchWord';
import ChatButton from './ChatButton';


const App = () => {
  const [word, setWord] = useState('');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false); 
  const [searchHistory, setSearchHistory] = useState([]);
  const bottomRef = useRef(null);

  const exampleData = [
    {'type': 'main', 'first_consonant': 's_o', 'vowel': 'jo', 'final_consonant': null, 'accent': true},
    {'type': 'sub', 'first_consonant': null, 'vowel': "ʊ", 'final_consonant': null},
    {'type': 'main', 'first_consonant': "ɡ", 'vowel': "ə", 'final_consonant': null, 'accent': false},
    {'type': 'sub', 'first_consonant': "ɹ", 'vowel': null, 'final_consonant': null},
    {'type': 'sub', 'first_consonant': "t", 'vowel': null, 'final_consonant': null},
  ]

  const handleInputChange = (event) => {
    setWord(event.target.value);
  };

  const handleSearch = async (e) => {
    e.preventDefault();

      // 입력값이 빈칸이거나 공백만 있는 경우 전송을 방지
    if (!word.trim()) {
      return;
    }

    setData(false); // 기존 데이터 삭제
    setLoading(true); // 로딩 시작
    try{
      const response = await axios.get(`${process.env.REACT_APP_SERVER}/?word=${word.toLowerCase()}`);      
      setData(response.data);
      updateSearchHistory(word);      
    } catch (error) {
      toast.error('입력한 단어가 검색되지 않습니다...😂');
    } finally {
      clearInput();
      setLoading(false);
    }
  };

  // 입력된 단어를 삭제하는 함수 추가
  const clearInput = () => {
    setWord('');
  };

  const updateSearchHistory = (word) => {
    // 중복 검색어 제거 및 목록 끝에 추가
    let updatedHistory = searchHistory.filter(item => item !== word); // 기존 목록에서 현재 검색어 제거
    updatedHistory = [word, ...updatedHistory];
  
    // 최대 길이 유지: 10개 이상일 경우 가장 오래된 검색어 삭제
    if (updatedHistory.length > 10) {
      updatedHistory = updatedHistory.slice(0, 10); // 가장 최근 10개의 항목만 유지
    }
  
    setSearchHistory(updatedHistory);
    Cookies.set('searchHistory', JSON.stringify(updatedHistory), { expires: 7 });
  };
  

  const handleDelete = (wordToDelete) => {
    const updatedHistory = searchHistory.filter(item => item !== wordToDelete);
    setSearchHistory(updatedHistory);
    Cookies.set('searchHistory', JSON.stringify(updatedHistory), { expires: 7 });
  };

  const reSearch = (word) => {
    setWord(word);
    handleSearch(new Event('re-search'));
  };


  // Add this useEffect hook to attach the event listener when the component mounts
  useEffect(() => {
    // 쿠키에서 검색 이력을 불러옵니다.
    const loadedHistory = Cookies.get('searchHistory');
    if (loadedHistory) {
      setSearchHistory(JSON.parse(loadedHistory));
    }

    const preventRightClick = (e) => {
      e.preventDefault();
    };

  
    // Attach the event listener
    window.addEventListener('contextmenu', preventRightClick);

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('contextmenu', preventRightClick);
    };
  }, []);
  
  // Scroll to bottom when data is set
  useEffect(() => {
    if (data) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [data]);

  return (
    <div className="container">
      <ToastContainer />
      <div className="content">
        <div className="title">
          <h1 className="app-title">신한글 영어 발음사전</h1>
          <img src="/images/app_logo.svg" alt="App Logo" className="app-logo" />
  
        </div>
     
        <div className="description">
          <p className="description-text">
            <b>한글의 신비를 느껴보세요!</b><br></br>
            훈민정음의 창제원리를 기반으로 미국식 영어 발음을 시각화해주는
            신한글 시스템을 활용한 영어 발음 사전입니다.
          </p>
          <div className="divider"></div>

          <div className="example">
            <span><b>yogurt는 요거트가 아니라...</b></span>
            <div className="ipa-bundles" style={{ pointerEvents: 'none' }}>
              {exampleData.map((bundle, index) => {
                if (bundle.type === "main") {
                  return <MainBundle key={index} bundle={bundle} />
                } else if (bundle.type === "sub") {
                  return <SubBundle key={index} bundle={bundle} />
                }
                return null;
              })}
            </div>
          </div>
        </div>
        

      <form onSubmit={handleSearch}>
        <div className="search-container">
          <FaSearch className="search-icon" />
          <input
            type="text"
            className="search-input"
            value={word}
            onChange={handleInputChange}
            placeholder="검색할 영어 단어 입력..."
          />
          {word && <FaTimes className="clear-icon" onClick={clearInput} />}
          <button type="submit" className="search-button">검색</button>
        </div>
      </form>

      <SearchWord
        searchHistory={searchHistory}
        onDelete={handleDelete}
        onReselect={reSearch}
      />

      {loading && <div className="loading"><div className="loading-spinner"></div></div>}
      {data && <Card data={data} />}
      {/* {data && <RuleCard data={data.ipa_bundles} />} */}

      </div>

      <ChatButton />

      {/* <div className="floating-bar">
        <a href="https://example.com/기호체계" target="_blank" rel="noopener noreferrer">
          <FaInfoCircle />기호체계
        </a>
        <a href="https://example.com/발전방향" target="_blank" rel="noopener noreferrer">
          <FaFlask />발전방향
        </a>
        <a href="https://example.com/후원하기" target="_blank" rel="noopener noreferrer">
          <FaDonate />후원하기
        </a>
        <a href="https://example.com/문의하기" target="_blank" rel="noopener noreferrer">
          <FaEnvelope />문의하기
        </a>
      </div> */}
      <div ref={bottomRef}></div>
    </div>
  );
};

export default App;