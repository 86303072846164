/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './SubBundle.css';
import getVowelPos from './vowelPosition.js';

const SubBundle = ({ bundle, selectedImage, bundleIndex, onImageClick }) => {
  const vowelPosition = getVowelPos(bundle.vowel);

  const isSelected = (type) => {
    return selectedImage?.bundleIndex === bundleIndex && selectedImage?.type === type;
  };

  const getBorderStyle = (type) => {
    return isSelected(type) ? { outline: '2px solid red' } : {};
  };

  return (
    <div className="ipa-sub-bundle">

      {bundle.first_consonant && 
        <img 
          src={`/images/consonants/${bundle.first_consonant}.svg`} 
          className={`sub-first-consonant-${vowelPosition} ${bundle.final_consonant ? 'with-final' : ''}`}
          style={getBorderStyle('first_consonant')}
          onClick={() => onImageClick(bundleIndex, 'first_consonant')}
        />
      }

      {bundle.vowel && 
        <img
          src={`/images/vowels/${bundle.vowel}.svg`}
          className={`sub-vowel-${vowelPosition} ${bundle.final_consonant ? 'with-final' : ''}`}
          style={getBorderStyle('vowel')}
          onClick={() => onImageClick(bundleIndex, 'vowel')}
        />
      }

      {bundle.final_consonant && 
        <img 
          src={`/images/bases/b_${bundle.final_consonant}.svg`}
          className="sub-final-consonant"
          style={getBorderStyle('final_consonant')}
          onClick={() => onImageClick(bundleIndex, 'final_consonant')}
        />
      }

    </div>
  );
};

export default SubBundle;
