import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css'; // 스타일을 위한 CSS 파일

function NavBar() {
  return (
    <nav className="navbar">
      <div className="navbar-container">
        {/* 로고 이미지와 홈 링크 */}
        <Link to="/" className="navbar-logo">
          <img src="/images/app_logo.svg" alt="App Logo" />
        </Link>

        {/* 오른쪽 링크 */}
        <div className="navbar-menu">
          <Link to="/system" className="navbar-item"><b>시스템 소개</b></Link>
          <Link to="/phoneme" className="navbar-item"><b>음소 소개</b></Link>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
