import React from 'react';
import './SystemPage.css';

const SystemPage = () => {
  return (
    <div className="system-container">
        <div className="content">
            <div className="title">
                <h1 className="app-title">신한글 시스템 소개</h1>
            </div>

            <div className="description">
                <p className="description-text">
                <div className="divider"></div>
                <br></br>
                현존하는 모든 기호는 발음을 완벽히 정확하게 나타낼 수 없습니다. 그렇지만, 신한글 시스템은 한국어의 다양한 소리와 한글 기호를 최대한 활용하여 시각화함으로써 더 나은 외국어 발음을 유도하고 외국인의 단어 인식률을 향상 시킬 수 있는 방법을 제공합니다.
                원어민이 듣기에는 이 발음이 다소 어색할 수 있지만, 다른 단어와 혼동되지 않도록 유도한 발음이므로, 발음 초보에서 중급자까지 사용할 수 있습니다. 
                높은 수준의 발음 스킬이 필요한 경우 혹은 중상급자 이상의 경우에는 이 시스템을 보조 도구로만 사용하시길 바랍니다.
                <br></br>
                <br></br>
                신한글 시스템은 이 신한글 표기법을 사용해 아래 세 단계에 걸쳐 영어 발음이 한국어 발음으로 어떻게 쪼개질 수 있는지 보여주고 경험할 수 있도록 도와주는 시각화 도구입니다.  
                이 과정을 이해하시고, 아래 4단계에 따라서 가능한 한 정확하게 발음을 따라해보세요. 이 네 단계를 모두 잘 하신다면, 이제는 원어민과 직접 대화하며 실력을 쌓으시는 방향으로 가주세요. 
                <br></br>
                <br></br>
                <div className="divider"></div>
                <br></br>
                <b>[1단계] 단어 자체의 발음 이해하기</b>
                <br></br> (함께 보여준 신한글표기법 및 국제음성기호를 이용하여) 
                <br></br>
                Salmon (연어)의 발음을 아시나요? 연어 = salmon이라는 스펠링만 외우면 Reading과 Writing이 되지만, 함께 발음까지 외워야 비로소 Listening과 Speaking이 가능해집니다. 쌀몬? 쌜먼? 정답은 쌔-먼에 가깝습니다. 스펠링에 L은 써있지만, 발음에는 소리가 나지 않습니다. 
                영어 공부를 하다가 모르는 단어를 보았을 때 스펠링을 외우는 것 뿐 아니라, (1) 발음 기호를 외우거나 (2) 특정 단어의 발음 방법을 몸으로 기억해합니다. 몸으로 기억하는 것이 제일 좋고, IPA기호로 외우는 것이 그 다음으로 좋지만, IPA 기호가 친숙하지 않은 분들을 위해 저희는 신한글표기법을 활용하여 발음을 표기하는 방법을 고안해냈습니다.  
                발음 기호가 궁금할 때, Posthangul.com은 영어 발음 사전으로써 단어 자체가 어떻게 발음되는 지 시각화하고, 이를 기억하는 것을 돕습니다. 
                <br></br>
                <br></br>
                <div className="divider"></div>
                <br></br>
                <b>[2단계] 단어의 강세와 박자를 아는 것 </b>
                <br></br>(함께 보여준 신한글 표기법의 첨자 및 회색글자) 
                <br></br>
                조금 어려운 단어, osmosis (삼투현상) 를 예로 들겠습니다. 위의 발음은 오스모시스가 아닌, 아zㅈ모ㅡ씨ㅆ입니다. 이를 높낮이를 똑같게, 6음절로 '아z즈모으씨스' 로 발음하면, 원어민은 절대 알아듣지 못합니다. 아zㅈ모ㅡ씨ㅆ에서 (모ㅡ)부분은 강하고 길게 발음하고, 나머지 아zㅈ와 씨ㅆ는 작고 빠르게 발음해야합니다. 영어는 단어를 발음할 때 박자와 높이가 있는 마치 노래와 같은 언어입니다.  
                Posthangul.com에서는 신한글표기법을 활용하여 강세 글자는 회색으로 표기되고, 크게 강조되어 표기됩니다. 이들은 크고 길게 강조하여 발음하고, 첨자로 작게 표기 되는 것은 빠르고 덜 강조하여 발음하도록 유도합니다.  
                <br></br>
                <br></br>
                <div className="divider"></div>
                <br></br>
                <b>[3단계] 한글 자모음을 이용하여 발음을 시각화 해줍니다.</b>
                <br></br> 음소 하나에 대한 신한글 표기법을 이해합니다. (개별 음소에 대한 설명은 여기를 <a href="/phoneme/"><b>참고</b></a>)  
                <br></br>
                aurora의 발음은 IPA로 표현했을 때, [ɔːˈrɔːr.ə]입니다. 두번이나 등장하는 ɔ기호를 가장 정확하게 발음하기 위해서는 원어민이 ɔ 기호를 발음할 때의 혀의 위치와 입술의 모양을 정확하게 따라 하면됩니다. 이는 오래 걸리고 쉽지 않을 수 있습니다. 완벽하게 정확하지는 않지만 한국어 '오'와 '어'의 중간 발음, 혹은 둘을 아주 빠르게 섞어서 발음하면 원어민이 무리없이 인식할 수 있을 정도로 상당히 비슷한 발음을 낼 수 있습니다.  
                한국인들은 우리도 모르게 자연스럽게 내는 L발음이 존재합니다. ㄹ받침 뒤에 오는 ㄹ자음, 예를 들렴 랄랄라에서 두번째 '랄'과 '라'를 발음할 때는 우리도 모르게 L발음을 하고 있습니다. 신한글 표기법은 기존 한국어 네애 적용하는 음소변이에서 이미 발음하고 있는 외국어의 음소들, "allophone"들을 외국어 발음 시에 최대한 활용할 수 있도록 유도합니다. 
                Posthangul.com은 한국어의 자모음을 최대한 활용하는 신한글표기법을 사용하여, 실제 원어민에 최대한 근접한 발음을 유도하여 빠른 시간 내에 단어의 인식률을 높이는 방법을 제시합니다.  
                <br></br>
                <br></br>
                <div className="divider"></div>
                <br></br>
                <b>[4단계] 이제 오디오 파일을 들어보시고, 따라해보세요.</b> 
                <br></br>
                <br></br>
                <div className="divider"></div>

                </p>
            </div>
        </div>
    </div>
  );
}

export default SystemPage;
