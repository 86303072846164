/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import './MainBundle.css';
import getVowelPos from './vowelPosition.js';

const MainBundle = ({ bundle, selectedImage, bundleIndex, onImageClick }) => {
  // const [selected, setSelected] = useState(null);
  const vowelPosition = getVowelPos(bundle.vowel);

  const isSelected = (type) => {
    return selectedImage?.bundleIndex === bundleIndex && selectedImage?.type === type;
  };

  const getBorderStyle = (type) => {
    return isSelected(type) ? { outline: '2px solid red' } : {};
  };

  const style = {
    backgroundColor: bundle.accent ? 'lightgray' : 'none',
    width: bundle.accent? '4.5rem' : '3rem',
    height: bundle.accent? '4.5rem' : '3rem'
  };

  return (
    <div className="ipa-main-bundle" style={style}>
      
      {bundle.first_consonant && 
        <img 
          src={`/images/consonants/${bundle.first_consonant}.svg`} 
          className={`main-first-consonant-${vowelPosition} ${bundle.final_consonant ? 'with-final' : ''}`}
          style={getBorderStyle('first_consonant')}
          onClick={() => onImageClick(bundleIndex, 'first_consonant')}
        />
      }

      {bundle.vowel && 
        <img 
          src={`/images/vowels/${bundle.vowel}.svg`}
          className={`main-vowel-${vowelPosition} ${bundle.final_consonant ? 'with-final' : ''}`}
          style={getBorderStyle('vowel')}
          onClick={() => onImageClick(bundleIndex, 'vowel')}
        />
      }

      {bundle.final_consonant && 
        <img
          src={`/images/bases/b_${bundle.final_consonant}.svg`}
          className="main-final-consonant"
          style={getBorderStyle('final_consonant')}
          onClick={() => onImageClick(bundleIndex, 'final_consonant')}
        />
      }
      
    </div>
  );
};

export default MainBundle;
