import React from 'react';
import './SearchWord.css';
import { FaTimes } from 'react-icons/fa'; // FaTimes 추가


const SearchWord = ({ searchHistory, onDelete, onReselect }) => {
  return (
    <div className="search-history-container">
      {searchHistory.map((item, index) => (
        <div key={index} className="history-item">
          <span onClick={() => onReselect(item)}>{item}</span>
          <FaTimes className="clear-icon" onClick={() => onDelete(item)}/>
        </div>
      ))}
    </div>
  );
};

export default SearchWord;
