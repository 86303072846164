import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App.js';
import SystemPage from './SystemPage.js';
import PhonemePage from './PhonemePage.js';
import NavBar from './NavBar.js';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <NavBar/>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/system" element={<SystemPage />} />
        <Route path="/phoneme/:_phoneme?" element={<PhonemePage />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);