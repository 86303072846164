import React, { useState } from 'react';
import { FaVolumeUp, FaDownload, FaQuestionCircle, FaRegQuestionCircle } from 'react-icons/fa';
import MainBundle from './MainBundle.js';
import SubBundle from './SubBundle.js';
import { toPng } from 'html-to-image';
import { saveAs } from 'file-saver';
import './Card.css';
import PhonemeRule from './PhonemeRule.js';

const Card = ({ data }) => {
  const [selectedImage, setSelectedImage] = useState({ bundleIndex: null, type: '' });
  const [isManualVisible, setIsManualVisible] = useState(false);
  const selectedPhoneme = selectedImage.type ? data.ipa_bundles[selectedImage.bundleIndex][selectedImage.type] : null;

  const handleManualToggle = () => {
    setIsManualVisible(!isManualVisible);
  };

  const handleImageClick = (bundleIndex, type) => {
    setSelectedImage({ bundleIndex, type });
  };


  const ipaContentRef = React.useRef(null);
  const signatureRef = React.useRef(null);


  const exportToPng = () => {
    const ipaContent = ipaContentRef.current;
    const signature = signatureRef.current;
    const ipaBundles = ipaContent.getElementsByClassName('ipa-bundles')[0];

    // Change flex-flow to row and append signature
    ipaBundles.style.flexFlow = 'row';
    signature.style.display = 'flex';

    toPng(ipaContent)
      .then((dataUrl) => {
        const img = new Image();
        img.src = dataUrl;
        saveAs(dataUrl, '[posthangul] ' + data.word + '.png');
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      })
      .finally(() => {
        // Revert flex-flow to row wrap and remove signature
        ipaBundles.style.flexFlow = 'row wrap';
        signature.style.display = 'none';
    });
  }
  
  return (
    <div className="card">
      <h1>{data.word}</h1>
      <div className="ipa-audio">
        <div className="audio-button" onClick={() => document.getElementById('audio-control').play()}>
          들어보기&nbsp;<FaVolumeUp />
        </div>
        <audio id="audio-control" src={data.audio_url} />
        <span className="ipa-text">{data.ipa_text}</span>
      </div>
      <div className="ipa-manual">
        <div className="manual-button" onClick={handleManualToggle}>
          읽어보기&nbsp;<FaRegQuestionCircle />
        </div>
        <span className="manual-title">자모음을 클릭해보세요!</span>
        <div className="manual-text" style={{ display: isManualVisible ? 'block' : 'none' }}> 
          • 상자의 글자를 모두 <b>빠르게</b> 발음하세요. <br></br> 
          • 회색 글자 상자는 <b>세게</b> 발음하세요. <br></br> 
          • 작은 글자 상자는 <b>살짝</b> 발음하세요. <br></br>
          • 이미지를 <a href="#" onClick={exportToPng}><b>저장</b></a>해서 자주 보면서 연습하세요. <br></br> 
          • 자세한 설명은 <a href="/system"><b>여기</b></a>를 참고하세요. <br></br> 
        </div>
      </div>


      <div className="ipa-content" ref={ipaContentRef}>
        <div className="ipa-bundles">
          {data.ipa_bundles.map((bundle, index) => {
            if (bundle.type === "main") {
              return (
                <MainBundle 
                  key={index} 
                  bundle={bundle} 
                  selectedImage={selectedImage} 
                  bundleIndex={index}
                  onImageClick={handleImageClick}
                />
              );
            } else if (bundle.type === "sub") {
              return (
                <SubBundle 
                  key={index} 
                  bundle={bundle} 
                  selectedImage={selectedImage} 
                  onImageClick={handleImageClick}
                  bundleIndex={index} 
              />
              );
            }
            return null; // 혹은 기타 다른 타입에 대한 처리를 여기에 추가할 수 있습니다.
          })}
        </div>
        <div className="signature" ref={signatureRef}>
            <img src="/images/app_logo.svg" alt="Logo" />
            <p>posthangul.com</p>
        </div>
      </div>

      {selectedPhoneme && (
        <>
          <div className="divider"></div>
          <PhonemeRule phoneme={selectedPhoneme} type={selectedImage.type} />
        </>
      )}

      <div className="divider"></div>
      <div className="means-list">
        {data.means_list.map((item, index) => (
          <div key={index} className="mean-item">
            <strong>({item.class}) </strong> {item.value}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
