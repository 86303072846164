import React from 'react';
import { FaComment } from 'react-icons/fa';
import './ChatButton.css';

const ChatButton = () => {
  return (
    <a href="http://pf.kakao.com/_MnxmxcG" target="_blank" rel="noopener noreferrer" className="chat-button">
      <span className="chat-text">문의 & 후원하기</span>
      <FaComment />
    </a>
  );
};

export default ChatButton;
