const RIGHT_VOWELS = ['ɑ', 'æ', 'i', 'ɪ', 'ɒ', 'ɛ', 'e' ,'ʌ', 'jɑ', 'jæ', 'jɒ', 'jɛ', 'jʌ', 'ji', 'jɪ'];
const BOTTOM_VOWELS = ['o', 'u', 'ʊ', 'jo', 'ju', 'jʊ'];
const FULL_VOWELS = ['ɜ', 'ə', 'ɔ', 'jɜ', 'jɔ', 'jə' ];

// 모음의 위치를 반환하는 함수
function getVowelPos(vowel) {
    if (!vowel) {
        return null;
    }
    if (RIGHT_VOWELS.includes(vowel)) {
        return 'right';
    } 
    else if (BOTTOM_VOWELS.includes(vowel)) {
        return 'bottom';
    } 
    else if (FULL_VOWELS.includes(vowel)){
        return 'full';
    } else{
        return 'null';
    }
}

export default getVowelPos;